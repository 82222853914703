import router from "@/router";
import axios from "@axios";
import moment from "moment";
import { initialAbility } from '@/libs/acl/config'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
	namespaced: true,
	state: {
		users: {},
		totalPages: 0,
	},
	getters: {},
	mutations: {
		SET_BEARER(state, accessToken) {
			localStorage.removeItem(["accessToken", "expiresIn"]);
			axios.defaults.headers.Authorization = "Bearer " + accessToken;
		},
		UPDATE_USER_INFO(state, users){
			state.users = users
		}
	},
	actions: {
		login({ commit }, payload) {
			const requestBody = {
				email: payload.userDetails.user_name,
				password: payload.userDetails.password
			}
			return new Promise((resolve, reject) => {
				localStorage.removeItem([
					"accessToken",
					"expiresIn"
				]);
				
				axios.post("/login", requestBody).then(response => {
					console.log(response.data.userData.rules )
					if (response.data.accessToken) {
						localStorage.setItem(
							"accessToken",
							response.data.accessToken
						);
						// localStorage.setItem(
						// 	"expiresIn", response.data.expires_in
						// );
						if(response.data.userData.rules == 'admin'){
							response.data.userData.ability.push({
								'action': 'read',
								'subject': 'Ranking',
							})
							localStorage.setItem('userData', JSON.stringify(response.data.userData))
							commit("UPDATE_USER_INFO", response.data.userData);
						}

						if(response.data.userData.rules == 'colaboradores'){
							response.data.userData.ability.push({
								'action': 'read',
								'subject': 'Ranking',
							})
							localStorage.setItem('userData', JSON.stringify(response.data.userData))
							commit("UPDATE_USER_INFO", response.data.userData);
						}
						
						commit("SET_BEARER", response.data.accessToken);
					}
					// if (response.data.userData) {
					//	commit("UPDATE_USER_INFO", response.data.userData);
					//	response.data.userData.ability.push({
					//		'action': 'read',
					//		'resource': 'Auth',
					//	})
					//	localStorage.setItem('userData', JSON.stringify(response.data.userData))
					// }
					resolve(response);
				}).catch(() => {
					return reject({
						message: "Algo deu errado, por favor tente novamente."
					});
				});
			});
		},
	}
};
