import { Ability } from '@casl/ability'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
let newAbilities = initialAbility
const userData = JSON.parse(localStorage.getItem('userData'))
if(userData && userData.ability){
    newAbilities = initialAbility.concat(userData.ability)
}
export default new Ability(newAbilities)