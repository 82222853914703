/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/rankingColaborador',
      name: 'rankingColaborador',
      component: () => import('@/views/ranking/RankingColaborador.vue'),
      meta: {
        pageTitle: 'Ranking Colaborador',
        breadcrumb: [
          {
            text: 'Ranking Colaborador',
            active: true,
          },
        ],
      },
    },
    {
      path: '/rankingBonificacao',
      name: 'rankingBonificacao',
      component: () => import('@/views/ranking/RankingBonificacao.vue'),
      meta: {
        pageTitle: 'Ranking Bonificação',
        breadcrumb: [
          {
            text: 'Ranking Bonificação',
            active: true,
          },
        ],
      },
    },
    {
      path: '/regionais',
      name: 'regionais',
      component: () => import('@/views/admin/regional/Index.vue'),
      meta: {
        pageTitle: 'Regionais',
        breadcrumb: [
          {
            text: 'Regionais',
            active: true,
          },
        ],
      },
    },
    {
      path: '/agencias',
      name: 'agencias',
      component: () => import('@/views/admin/agencia/Index.vue'),
      meta: {
        pageTitle: 'Agencias',
        breadcrumb: [
          {
            text: 'Agencias',
            active: true,
          },
        ],
      },
    },
    {
      path: '/carteiras',
      name: 'carteiras',
      component: () => import('@/views/admin/carteira/Index.vue'),
      meta: {
        pageTitle: 'Carteiras',
        breadcrumb: [
          {
            text: 'Carteiras',
            active: true,
          },
        ],
      },
    },
    {
      path: '/colaboradores',
      name: 'colaboradores',
      component: () => import('@/views/admin/colaborador/Index.vue'),
      meta: {
        pageTitle: 'Colaboradores',
        breadcrumb: [
          {
            text: 'Colaboradores',
            active: true,
          },
        ],
      },
    },
    {
      path: '/produtos',
      name: 'produtos',
      component: () => import('@/views/admin/produto/Index.vue'),
      meta: {
        pageTitle: 'Produtos',
        breadcrumb: [
          {
            text: 'Produtos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/uad',
      name: 'uads',
      component: () => import('@/views/admin/uad/Index.vue'),
      meta: {
        pageTitle: 'UAD',
        breadcrumb: [
          {
            text: 'UAD',
            active: true,
          },
        ],
      },
    },
    {
      path: '/uad/acao',
      name: 'uad_acaos',
      component: () => import('@/views/admin/uadAcao/Index.vue'),
      meta: {
        pageTitle: 'Ação',
        breadcrumb: [
          {
            text: 'Ação',
            active: true,
          },
        ],
      },
    },
    {
      path: '/historicos',
      name: 'historicos',
      component: () => import('@/views/config/historico/Index.vue'),
      meta: {
        pageTitle: 'Historicos',
        breadcrumb: [
          {
            text: 'Historicos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/campanhas',
      name: 'campanhas',
      component: () => import('@/views/config/campanha/Index.vue'),
      meta: {
        pageTitle: 'Campanhas',
        breadcrumb: [
          {
            text: 'Campanhas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ranking-uad',
      name: 'ranking_uad',
      component: () => import('@/views/ranking/uad/Index.vue'),
      meta: {
        pageTitle: 'UAD',
        breadcrumb: [
          {
            text: 'UAD',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ranking-cooperativa',
      name: 'ranking_coop',
      component: () => import('@/views/ranking/rankingCoop/Index.vue'),
      meta: {
        pageTitle: 'Ranking Cooperativa',
        breadcrumb: [
          {
            text: 'Ranking Cooperativa',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ranking-regional',
      name: 'ranking_regional',
      component: () => import('@/views/ranking/rankingRegional/Index.vue'),
      meta: {
        pageTitle: 'Ranking Regional',
        breadcrumb: [
          {
            text: 'Ranking Regional',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ranking-porte',
      name: 'ranking_porte',
      component: () => import('@/views/ranking/rankingPorte/Index.vue'),
      meta: {
        pageTitle: 'Ranking Porte',
        breadcrumb: [
          {
            text: 'Ranking Porte',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ranking-agencia',
      name: 'ranking_agencia',
      component: () => import('@/views/ranking/rankingAgencia/Ranking.vue'),
      meta: {
        pageTitle: 'Ranking Agencia',
        breadcrumb: [
          {
            text: 'Ranking Agencia',
            active: true,
          },
        ],
      },
    },
    {
      path: '/prospera/pf',
      name: 'prospera_pf',
      component: () => import('@/views/ranking/prospera/RankingPF.vue'),
      meta: {
        pageTitle: 'Ranking PF',
        breadcrumb: [
          {
            text: 'Ranking PF',
            active: true,
          },
        ],
      },
    },
    {
      path: '/prospera/pj',
      name: 'prospera_pj',
      component: () => import('@/views/ranking/prospera/RankingPJ.vue'),
      meta: {
        pageTitle: 'Ranking PJ',
        breadcrumb: [
          {
            text: 'Ranking PJ',
            active: true,
          },
        ],
      },
    },
    {
      path: '/prospera/agencia',
      name: 'prospera_agencia',
      component: () => import('@/views/ranking/prospera/AgenciaRanking.vue'),
      meta: {
        pageTitle: 'Ranking Agencia',
        breadcrumb: [
          {
            text: 'Ranking Agencia',
            active: true,
          },
        ],
      },
    },
    {
      path: '/importacao',
      name: 'importacao_dinamica',
      component: () => import('@/views/config/importacao/Index.vue'),
      meta: {
        pageTitle: 'Importação Campanha',
        breadcrumb: [
          {
            text: 'Importação Campanha',
            active: true,
          },
        ],
      },
    },
    {
      path: '/importacao-geral',
      name: 'importacao_geral',
      component: () => import('@/views/config/importacao/IndexGeral.vue'),
      meta: {
        pageTitle: 'Importação Geral',
        breadcrumb: [
          {
            text: 'Importação Geral',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
