/* eslint-disable */
import Vue from 'vue'
import { 
  ToastPlugin, 
  ModalPlugin, 
  BootstrapVue,
  BootstrapVueIcons
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";

import moment from 'moment'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/acl'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.filter('dinheiro', function (value) {
  return parseFloat(value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
});

Vue.filter('media', function (value) {
  return parseFloat(value).toFixed(2) + '%';
});


Vue.filter('dateFormat', function (valor) {
  if (valor && valor.length > 0) {
    return moment(String(valor)).format('DD/MM/YYYY')
  }
})

Vue.filter('dateTimeFormat', function (valor) {
  return moment(String(valor)).format('DD/MM/YYYY HH:mm')
})

// Sentry.init({
//   Vue,
//   dsn: "https://dfc86e2eeb864c20bb83f06ff266f9d8@o1372533.ingest.sentry.io/6677668",
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ["localhost", "prospera-sicoob.netlify.app", /^\//],
//     }),
//   ],
//   tracesSampleRate: 1.0,
// });

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
