/* eslint-disable */
import axios from "@axios"

export default {
  namespaced: true,
  state: {
    modules: {
      agencias: [],
      regionais: [],
      carteiras: [],
      uad: [],
      uad_acao: [],
      ranking_uad: [],
      colaboradores: [],
      produtos: [],
      historicos: [],
      campanhas: [],
      ranking_index: [],
      ranking_cooperativa: [],
      ranking_regional: [],
      ranking_porte: [],
      ranking_agencia: [],
      ranking_top: [],
      ranking_bonificacao: [],
      agencias_porte: [],
      produtos_import: [],
      importacao: {
        cartoes: [],
        consorcio: [],
        credito_consignado: [],
        capital: [],
        previdencia: [],
        seguro_auto: [],
        seguro_vida: [],
        seguro_patrimonial: [],
        investimento: [],
        sipag: [],
        iap: [],
        coop_dig_pix: [],
        cobranca_bancaria: [],
        orcamento: [],
        resultado: []
      }
    },
    moduleDetails: {
      agencias: [],
      regionais: [],
      carteiras: [],
      colaboradores: [],
      produtos: [],
      historicos: [],
      campanhas: [],
      ranking_index: [],
      ranking_cooperativa: [],
      ranking_regional: [],
      ranking_porte: [],
      ranking_agencia: []
    },
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    // getLessonsById: (state) => (id) => {
    //   return state.lessons.find(lesson => lesson.id === id)
    // }
  },
  mutations: {
    SET_MODULES(state, payLoad) {
      state.modules[payLoad.path] = Object.values(payLoad.data);
    },
    SET_MODULE_DETAILS(state, payLoad) {
      state.moduleDetails[payLoad.path] = payLoad.data;
    },
    ADD_MODULE(state, payLoad){
      state.modules[payLoad.path].unshift(payLoad.data);
    },
    IMPORT_MODULE(state, payLoad){
      state.modules.produtos_import.unshift(payLoad.data);
    },
    UPDATE_MODULE(state, payLoad) {
      const modulesIndex = state.modules[payLoad.path].findIndex((p) => p.id == payLoad.data.data.id);
      Object.assign(state.modules[payLoad.path][modulesIndex], payLoad.data.data);
    },
    UPDATE_MODULE_COLABORADOR(state, payLoad) {
      const modulesIndex = state.modules[payLoad.path].findIndex((p) => p.id == payLoad.data.id);
      Object.assign(state.modules[payLoad.path][modulesIndex], payLoad.data.data);
    },
    REMOVE_MODULE(state, payLoad) {
      const moduleIndex = state.modules[payLoad.path].findIndex((p) => p.id == payLoad.data);
      state.modules[payLoad.path].splice(moduleIndex, 1);
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination;
    }

  },
  actions: {
    fetchModules({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/${args.pathAPI}`, {
          params: args,
        }).then(({ data }) => {  
          if(args.paginated == false){
            commit('SET_MODULES', {data: {...data}, path: args.pathAPI})
          } else {
            commit('SET_MODULES', {data: {...data.data}, path: args.pathAPI})
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchRanking({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/ranking_${args.pathAPI}`, {
          params: args,
        }).then(({ data }) => {  
          if(args.paginated == false){
            commit('SET_MODULES', {data: {...data}, path: 'ranking_' + args.pathAPI})
          } else {
            commit('SET_MODULES', {data: {...data.data}, path: 'ranking_' +args.pathAPI})
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },


    fetchRankingTop({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/ranking_top`, {
          params: args,
          colaborador: args.colaborador
        }).then(({ data }) => {  
          if(args.paginated == false){
            commit('SET_MODULES', {data: {...data}, path: 'ranking_top'})
          } else {
            commit('SET_MODULES', {data: {...data}, path: 'ranking_top'})
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
          resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get(`/${args.pathAPI}/${args.id}`, {
          params: args,
        }).then(({ data }) => {
          if (args.paginated == false) {
            commit('SET_MODULE_DETAILS', {data: {...data}, path: args.pathAPI})
          } else {
            commit('SET_MODULE_DETAILS', {data: {...data}, path: args.pathAPI})
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          }
          resolve(data)
        }).catch((error) => { reject(error) })

      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/${data.data.pathAPI}`, data.data,{ 
          headers: { 
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          commit('ADD_MODULE', {data: {...response.data}, path: data.data.pathAPI})
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

    isDisabled({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/is_disabled`, data.data,{ 
          headers: { 
            'Content-Type': 'application/json'
          }
        }).then((response) => {
          commit('UPDATE_MODULE_COLABORADOR', {data: {...response.data}, path: 'colaboradores'})
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

    import_produto({ commit }, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("excel", data.data.excel );
        formData.append("produto_id", data.data.produto_id );
        formData.append("usuario_id", data.data.usuario_id );
        formData.append("mes_ref", data.data.mes_ref );
        axios.post(`/import-produtos`, formData,{ 
          headers: { 
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          commit('IMPORT_MODULE', {data: {...response.data}, path: 'produtos_import'})
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

    import_data({ commit }, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("excel", data.excel );
        axios.post(`/import-${data.pathAPI}`, formData,{ 
          headers: { 
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          commit('ADD_MODULE', {data: {...response.data}, path: data.pathAPI})
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/${data.data.pathAPI}/${data.data.id}`, {
          ...data.data
        })
        .then((response) => {
            context.commit('UPDATE_MODULE', {data: {...response.data.data}, path: data.data.pathAPI})
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/${data.pathAPI}/${data.id}`) 
          .then((response) => { 
            context.commit('REMOVE_MODULE', {data: {...response.data}, path: data.pathAPI}) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

  },
}
